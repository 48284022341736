import React from "react";
import styled from "styled-components";

import { Box, Badge, List } from "../../components/Core";

import imgB1 from "../../assets/image/jpeg/blog-details-img-1.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

const UpdateVehicleStatus = () => (
  <>
    {/* <!-- Blog section --> */}
    <Post>
      <div>
        <iframe
          width="730"
          height="412"
          src="https://www.youtube.com/embed/pF6dH7Hi-5g"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <div>
        <h3>What is vehicle status?</h3>
        <p>
          The vehicle status shows what stage a vehicle is in. A vehicle can be
          in any of the following statuses:
        </p>
        <List>
          <li>
            <b>Read</b>: The Vehicle is available and ready to loan.
          </li>
          <li>
            <b>Loaned</b>: The vehicle is out on loan.
          </li>
          <li>
            <b>Returned</b>: The vehicle is back from a contract but is not yet
            ready to be loaned again. For example, the car needs to be cleaned
            first.
          </li>
          <li>
            <b>Maintenance</b>: The Vehicle needs repair and is not available to
            loan.
          </li>
          <li>
            <b>Hold</b>: The Vehicle is on hold and is not available to loan.
          </li>
        </List>
        <p>
          Vehicle status can only be changed for vehicles NOT in a Loaned
          status. Once a contract is finished, the vehicle status will move from
          Loaned to Returned.
        </p>

        <h3>To update a vehicle status</h3>
        <List>
          <li>Log into your LoanerHub Account</li>
          <li>Click 'VEHICLES' from the left menubar</li>
          <li>
            Click the 'DETAILS' button in the 'ACTIONS' column for the vehicle
            you want to edit
          </li>
          <li>
            Click the three-dot menu on the upper right of the vehicle's
            details.
          </li>
          <li>Click 'Change Status'.</li>
        </List>
      </div>
    </Post>
  </>
);

export default UpdateVehicleStatus;
